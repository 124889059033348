.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    /* Adjust the blur value according to your preference */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* Adjust the z-index to make sure the overlay is on top of other elements */
}

.overlay>.ant-spin {
    position: relative;
    z-index: 1001;
    /* Ensure the Spin component is on top of the overlay */
    color: #ffffff;
    /* Set the color to white */
}
.phone-input-classname .PhoneInputInput {
    padding: 5px;
}