@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://rsms.me/inter/inter.css");
@tailwind base;
@tailwind components;
@tailwind utilities;
.back-button {
    @apply lg:px-16 py-2 rounded-[12px] text-gray-600 border-2 bg-white text-lg font-semibold sm:px-5 sm:text-sm
}

.skip-button {
    @apply lg:px-16 py-2 sm:px-5 rounded-[12px] text-gray-600 border-2 bg-white lg:text-lg sm:text-sm font-semibold
}

.next-button {
    @apply lg:px-16 py-2 sm:px-5 rounded-[12px] text-white lg:text-lg sm:text-sm font-semibold z-[10]
}


/*html {
    font-family: "Inter", sans-serif;
}

@supports (font-variation-settings: normal) {
    html {
        font-family: "Inter var", sans-serif;
    }
}

.app {
    @apply relative w-full h-screen overflow-hidden;
}*/


/*.home {
    @apply w-fit xl: h-full flex xl: justify-between justify-start items-start flex-col xl: py-8 xl: px-36 sm: p-8 p-6 max-xl: gap-7 absolute z-10;
}*/


/*.home-content {
    @apply flex-1 xl: justify-center justify-start flex flex-col gap-10;
}

.head-text {
    @apply xl: text-[10rem] text-[6rem] xl: leading-[11rem] leading-[7rem] font-black text-black;
}

.download-btn {
    @apply w-14 h-14 flex justify-center items-center rounded-full glassmorphism cursor-pointer outline-none;
}

.editortabs-container {
    @apply glassmorphism w-16 border-[2px] rounded-lg flex flex-col justify-center items-center ml-1 py-4 gap-4;
}

.filtertabs-container {
    @apply absolute z-10 bottom-5 right-0 left-0 w-full flex justify-center items-center flex-wrap gap-4;
}

.aipicker-container {
    @apply absolute left-full ml-3 glassmorphism p-3 w-[195px] h-[220px] rounded-md flex flex-col gap-4;
}

.aipicker-textarea {
    @apply w-full bg-transparent text-sm border border-gray-300 p-2 outline-none flex-1;
}

.filepicker-container {
    @apply absolute left-full ml-3 glassmorphism p-3 w-[195px] h-[220px] flex flex-col rounded-md;
}

.filepicker-label {
    @apply border border-gray-300 py-1.5 px-2 rounded-md shadow-sm text-xs text-gray-700 focus: outline-none focus: ring-1 focus: ring-blue-500 focus: border-blue-500 cursor-pointer w-fit;
}

.tab-btn {
    @apply w-14 h-14 flex justify-center items-center cursor-pointer select-none;
}*/


/*.glassmorphism {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 2px 30px 0 rgba(31, 38, 135, 0.07);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

input[type="file"] {
    z-index: -1;
    position: absolute;
    opacity: 0;
}

.sketch-picker {
    width: 170px !important;
    background: rgba(255, 255, 255, 0.25) !important;
    box-shadow: 0 2px 30px 0 rgba(31, 38, 135, 0.07) !important;
    backdrop-filter: blur(4px) !important;
    -webkit-backdrop-filter: blur(4px) !important;
    border: 1px solid rgba(255, 255, 255, 0.18) !important;
    border-radius: 6px !important;
}

.sketch-picker>div:nth-child(3) {
    display: none !important;
}*/